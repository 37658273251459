import React, { FC, PropsWithChildren } from "react";
import {
  Box,
  BoxProps,
  Collapse,
  Grid,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";

import Link from "../Link";

type SubMenuItemElementProps = {
  selected: boolean;
};
const SubMenuItemElement: React.FC<
  PropsWithChildren<SubMenuItemElementProps>
> = ({ children, selected, ...otherProps }) => {
  const bg = useColorModeValue("background.primary", "text.primary");
  const text = useColorModeValue("text.primary", "text.primaryDark");

  return (
    <Box
      as="h1"
      px="12px"
      py="10px"
      cursor="pointer"
      fontSize={["sm", "md", "md", "lg"]}
      bg={selected ? "brandSecondary.500" : "inherit"}
      color={selected ? "text.primaryDark" : "inherit"}
      _hover={{
        bg: selected ? "brandSecondary.500" : bg,
        color: selected ? "text.primaryDark" : text,
      }}
      {...otherProps}
    >
      <Grid
        templateColumns={{ base: "20px auto", md: "34px auto" }}
        alignItems="center"
        gap="2"
      >
        {children}
      </Grid>
    </Box>
  );
};

interface SubMenuItemProps extends BoxProps {
  /**
   * Url to link to
   */
  to?: string;
  /**
   * Whether the item is selected
   */
  selected?: boolean;
}

export const SubMenuItem: FC<SubMenuItemProps> = ({
  to,
  selected,
  ...otherProps
}) => {
  return to ? (
    <Link to={to}>
      <SubMenuItemElement selected={!!selected} {...otherProps} />
    </Link>
  ) : (
    <SubMenuItemElement selected={!!selected} {...otherProps} />
  );
};

export const SubMenuIcon: FC<BoxProps> = props => {
  return <Box {...props}>{props.children}</Box>;
};

export const SubMenuName: FC<BoxProps> = props => {
  return <Box {...props}>{props.children}</Box>;
};

interface SubMenuProps extends BoxProps {
  open: boolean;
}

export const SubMenu: FC<SubMenuProps> = props => {
  const [isMd] = useMediaQuery("(min-width: 48em)");

  const backroundColor = useColorModeValue(
    "background.secondary",
    "background.secondaryDark"
  );

  if (isMd) {
    return (
      <Box
        overflow="hidden" // to hide rounded corners while hovering
        borderRadius="lg"
        height="auto"
        w="100%"
        bg={backroundColor}
        {...props}
      >
        {props.children}
      </Box>
    );
  } else {
    return (
      <Collapse in={props.open} animateOpacity>
        <Box
          overflow="hidden" // to hide rounded corners while hovering
          borderRadius="lg"
          height="auto"
          w="100%"
          bg={backroundColor}
          {...props}
        >
          {props.children}
        </Box>
      </Collapse>
    );
  }
};
