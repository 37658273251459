import React, { useState } from "react";
import {
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  IconButton,
  Image,
  VStack,
} from "@chakra-ui/react";
import { FaHouseUser } from "react-icons/fa";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { LanguageSelector } from "../LanguageSelector";
import MenuButton from "../MenuButton";
import ProfileLinks from "../ProfileLinks";
import DarkModeSwitch from "../DarkModeSwitch";
import Link from "../Link";

import Part from "./Part";

type HeaderProps = {
  currentPage: string;
};

const Header: React.FC<HeaderProps> = ({ currentPage }) => {
  const [technologyOpen, setTechnologyOpen] = useState(false);
  const [educationOpen, setEducationOpen] = useState(false);
  const [entertainmentOpen, setEntertainmentOpen] = useState(false);

  const bigScreen = window.screen.availWidth > 500; // larger than 500px

  const open = technologyOpen && educationOpen && entertainmentOpen;

  const data = useStaticQuery(graphql`
    query GetProfileImage {
      imageSharp(fluid: { originalName: { eq: "andre_face.png" } }) {
        gatsbyImageData
      }
    }
  `);

  const image = data.imageSharp && getImage(data.imageSharp);

  return (
    <header>
      <Box bg="primary">
        <Container maxW="container.lg" py={5}>
          <Grid
            gridTemplateAreas={{ md: "'a b' 'c c' ", base: "'a' 'c' 'b'" }}
            rowGap="20px"
          >
            <Flex gridArea="a" justify="space-between" align="flex-start">
              <Flex>
                <Link
                  to="/"
                  transition="all 0.3s"
                  _hover={{
                    transform: "scale(1.1) rotate(3deg)",
                  }}
                >
                  <Image
                    as={GatsbyImage}
                    w="70px"
                    borderRadius="lg"
                    border="2px solid white"
                    objectFit="cover"
                    image={image}
                    alt="Andre Kovac profile picture"
                  />
                </Link>
                <VStack ml="5" align="flex-start">
                  <Link
                    to="/"
                    _hover={{
                      transform: "scale(1)",
                    }}
                  >
                    <Heading
                      as="h1"
                      color="white"
                      fontWeight="500"
                      fontSize={{ base: "2xl", md: "4xl" }}
                    >
                      André Kovac
                    </Heading>
                  </Link>
                </VStack>
              </Flex>
              <Box display={{ base: "block", md: "none" }}>
                <MenuButton
                  isOpen={open}
                  onClick={() => {
                    if (
                      !technologyOpen ||
                      !educationOpen ||
                      !entertainmentOpen
                    ) {
                      // if at least one part is closed -> open all
                      setTechnologyOpen(true);
                      setEducationOpen(true);
                      setEntertainmentOpen(true);
                    } else {
                      // if all parts are opened -> close all
                      setTechnologyOpen(false);
                      setEducationOpen(false);
                      setEntertainmentOpen(false);
                    }
                  }}
                  lineProps={{ strokeLinecap: "round" }}
                  transition={{ type: "spring", stiffness: 200, damping: 15 }}
                  style={{ marginLeft: "2rem" }}
                />
              </Box>
            </Flex>
            <Box
              gridArea="b"
              justifySelf={{ base: "center", md: "right" }}
              display={{
                base: `${open ? "block" : "none"}`,
                md: "block",
              }}
            >
              <Box display={{ base: "none", md: "flex" }}>
                <ProfileLinks iconSize={24} />
              </Box>
              <Box display={{ base: "flex", md: "none" }}>
                <ProfileLinks iconSize={20} />
              </Box>
              <Flex
                alignItems="center"
                // TODO: In case of more than one icon, justify content should be space-between
                // justify={currentPage !== "/" ? "space-between" : "flex-end"}
                // justify="flex-end"
                justify={{ base: "center", md: "flex-end" }}
                mt={4}
              >
                {/* TODO: Enable language selector */}
                {/* <LanguageSelector /> */}
                {currentPage !== "/" && (
                  <IconButton
                    display={{ base: "none", md: "flex" }}
                    aria-label="back home"
                    bg="transparent"
                    fontSize="20px"
                    icon={<FaHouseUser />}
                    onClick={() => navigate("/")}
                    color="background.primary"
                  />
                )}
                <DarkModeSwitch />
              </Flex>
            </Box>
            <Box gridArea="c">
              <Grid
                // gridTemplateAreas={{
                //   base: "'sa1 sb1' 'sa2 sb2' 'sc1 sd1' 'sc2 sd2'",
                //   md: "'sa1 sb1 sc1 . . sd1' 'sa2 sb2 sc2 . . sd2'",
                // }}
                // gridTemplateColumns="1fr 1fr"
                templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
                rowGap={3}
                columnGap={4}
              >
                <Part
                  currentPage={currentPage}
                  part="technology"
                  open={technologyOpen}
                  onTitleClick={() => {
                    setTechnologyOpen(!technologyOpen);
                    if (bigScreen) {
                      navigate("/");
                    }
                  }}
                />
                <Part
                  currentPage={currentPage}
                  part="education"
                  open={educationOpen}
                  onTitleClick={() => {
                    setEducationOpen(!educationOpen);
                    if (bigScreen) {
                      navigate("/");
                    }
                  }}
                />
                <Part
                  currentPage={currentPage}
                  part="entertainment"
                  open={entertainmentOpen}
                  onTitleClick={() => {
                    setEntertainmentOpen(!entertainmentOpen);
                    if (bigScreen) {
                      navigate("/");
                    }
                  }}
                />
                {/* <SubMenuHeaderText gridArea="sd1">Writing.</SubMenuHeaderText>
                <Box gridArea="sd2">
                  <SubMenu open={open}>
                    <SubMenuItem>
                      <SubMenuIcon>📲</SubMenuIcon>
                      <SubMenuName>Software Engineering</SubMenuName>
                    </SubMenuItem>
                    <SubMenuItem>
                      <SubMenuIcon>🐊</SubMenuIcon>
                      <SubMenuName>Education</SubMenuName>
                    </SubMenuItem>
                    <SubMenuItem>
                      <SubMenuIcon>🤓</SubMenuIcon>
                      <SubMenuName>Lists</SubMenuName>
                    </SubMenuItem>
                  </SubMenu>
                </Box> */}
              </Grid>
            </Box>
          </Grid>
        </Container>
      </Box>
    </header>
  );
};

export default Header;
