import React, { useEffect } from "react";
import { Container, Flex } from "@chakra-ui/react";
import { PageProps } from "gatsby";

import Header from "./Header/Header";
import Footer from "./Footer";

import CookieBanner from "./CookieBanner";

/**
 * Layout which surounds every component
 */
const Layout: React.FC<PageProps> = ({ children, location }) => {
  /**
   * Hide the loader once the page has loaded
   */
  useEffect(() => {
    const loader = document.getElementById("loader");
    loader!.style.display = "none";
  }, []);

  return (
    <Flex direction="column" h="100vh" w="100%">
      {/* <CookieBanner showStrictlyNecessary={false} /> */}

      <Header currentPage={location.pathname} />

      <Flex direction="column" flex={1}>
        <Container maxW="container.lg" my={10}>
          <main>{children}</main>
        </Container>
      </Flex>

      <Footer currentPage={location.pathname} />
    </Flex>
  );
};

export default Layout;
