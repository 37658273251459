import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import Chip from "../../components/Chip/style";

// color mode config
const config: ThemeConfig = {
  initialColorMode: "system",
};

// Created by retrieving scheme for '#384567' in https://smart-swatch.netlify.app/
const brandColorScheme = {
  50: "#e9f2ff",
  100: "#cbd4e8",
  200: "#abb7d3",
  300: "#8b9ac0",
  400: "#6b7dad",
  500: "#526494",
  600: "#3f4d74",
  700: "#2c3754",
  800: "#192135",
  900: "#050b18",
};

const secondaryColorScheme = {
  50: "#fff2da",
  100: "#ffdaae",
  200: "#ffc37d",
  300: "#ffab4c",
  400: "#ff931a",
  500: "#e67900",
  600: "#b35e00",
  700: "#814200",
  800: "#4f2700",
  900: "#200b00",
};

const primary = brandColorScheme[600];
const secondary = secondaryColorScheme[500];
const tertiary = brandColorScheme[900];

// Text
const textPrimary = "#222222";
const textSecondary = "#4D4D4D";

// Background
const backgroundPrimary = "#FBFBFB";
const backgroundSecondary = "#EBEBEB";

// Dark mode
const textPrimaryDarkMode = backgroundPrimary;
const textSecondaryDarkMode = backgroundSecondary;

const backgroundPrimaryDarkMode = "#3E3D3C";
const backgroundSecondaryDarkMode = textSecondary;

const link = secondaryColorScheme[600];
const linkHover = secondaryColorScheme[700];
const linkDarkMode = secondaryColorScheme[200];
const linkHoverDarkMode = secondaryColorScheme[500];

const theme = {
  config,
  styles: {
    global: (props: Record<string, any>) => ({
      body: {
        fontWeight: 500,
        color: mode(textPrimary, textPrimaryDarkMode)(props),
        bg: mode(backgroundSecondary, backgroundPrimaryDarkMode)(props),
        lineHeight: "base",
        // TODO: Check anchor styles in custom link component
        a: {
          color: mode(link, linkDarkMode)(props),
          _hover: {
            color: mode(linkHover, linkHoverDarkMode)(props),
            textDecoration: "none",
          },
        },
      },
    }),
  },
  fonts: {
    heading: "Libre Baskerville",
    body: "Montserrat",
  },
  colors: {
    white: "#fff",
    black: "#000",
    brand: brandColorScheme,
    brandSecondary: secondaryColorScheme,
    primary,
    secondary,
    tertiary,
    text: {
      primary: textPrimary,
      secondary: textSecondary,
      primaryDark: textPrimaryDarkMode,
      secondaryDark: textSecondaryDarkMode,
    },
    background: {
      primary: backgroundPrimary,
      secondary: backgroundSecondary,
      primaryDark: backgroundPrimaryDarkMode,
      secondaryDark: backgroundSecondaryDarkMode,
    },
    link: {
      primary: link,
      hover: linkHover,
      primaryDark: linkDarkMode,
      hoverDark: linkHoverDarkMode,
    },
  },
  components: {
    Chip,
  },
};

export default extendTheme(theme);
