import { ComponentStyleConfig } from "@chakra-ui/theme";
import { mode } from "@chakra-ui/theme-tools";

const Chip: ComponentStyleConfig = {
  variants: {
    solid: props => ({
      color: mode("text.primary", "text.primaryDark")(props),
      bg: mode("background.primary", "background.primaryDark")(props),
    }),
    outline: props => ({
      color: mode("text.primary", "text.primaryDark")(props),
      bg: mode("background.primary", "background.primaryDark")(props),
      borderColor: mode("background.primaryDark", "background.primary")(props),
      borderStyle: "solid",
      borderWidth: 1,
    }),
    gray: props => ({
      color: mode("text.primary", "text.primaryDark")(props),
      bg: mode("background.secondary", "background.secondaryDark")(props),
    }),
    dark: props => ({
      color: mode("text.primary", "text.primaryDark")(props),
      bg: mode("background.primary", "text.primary")(props),
    }),
  },
  defaultProps: {
    variant: "solid",
  },
};

export default Chip;
