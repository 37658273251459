import React from "react";
import { I18nextProvider } from "react-i18next";

import "@fontsource/libre-baskerville";
import "@fontsource/montserrat";
import "@fontsource/share-tech-mono";
import "@fontsource/pattaya";

import Layout from "./src/components/Layout";
import i18next from "./src/lib/i18n";

// Logs when the client route changes
// export const onRouteUpdate = ({ location, prevLocation }) => {
//   console.log("new pathname", location.pathname);
//   console.log("old pathname", prevLocation ? prevLocation.pathname : null);
// };

// Listen for system color mode changes
// window
//   .matchMedia("(prefers-color-scheme: dark)")
//   .addEventListener("change", event => {
//     const newColorScheme = event.matches ? "dark" : "light";
//   });

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

// Wraps the root element
export const wrapRootElement = ({ element }) => {
  return <I18nextProvider i18n={i18next}>{element}</I18nextProvider>;
};
