exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bio-tsx": () => import("./../../../src/pages/bio.tsx" /* webpackChunkName: "component---src-pages-bio-tsx" */),
  "component---src-pages-chinese-tsx": () => import("./../../../src/pages/chinese.tsx" /* webpackChunkName: "component---src-pages-chinese-tsx" */),
  "component---src-pages-event-art-tsx": () => import("./../../../src/pages/event-art.tsx" /* webpackChunkName: "component---src-pages-event-art-tsx" */),
  "component---src-pages-hackathons-tsx": () => import("./../../../src/pages/hackathons.tsx" /* webpackChunkName: "component---src-pages-hackathons-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-chinese-chinese-menu-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/chinese/ChineseMenu.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-chinese-chinese-menu-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-chinese-chinese-talk-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/chinese/ChineseTalk.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-chinese-chinese-talk-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-event-art-event-art-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/eventArt/EventArt.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-event-art-event-art-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-feedback-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/Feedback.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-feedback-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-feedback-selected-english-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/FeedbackSelectedEnglish.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-feedback-selected-english-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-feedback-selected-german-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/FeedbackSelectedGerman.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-feedback-selected-german-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-public-speaking-keynote-speaker-good-talk-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/publicSpeaking/KeynoteSpeakerGoodTalk.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-public-speaking-keynote-speaker-good-talk-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-public-speaking-keynote-speaker-languages-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/publicSpeaking/KeynoteSpeakerLanguages.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-public-speaking-keynote-speaker-languages-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-public-speaking-keynote-speaker-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/publicSpeaking/KeynoteSpeaker.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-public-speaking-keynote-speaker-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-public-speaking-technological-talks-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/publicSpeaking/TechnologicalTalks.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-public-speaking-technological-talks-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-science-communication-science-slam-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/scienceCommunication/ScienceSlam.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-science-communication-science-slam-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-software-engineering-consulting-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/softwareEngineering/Consulting.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-software-engineering-consulting-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-software-engineering-development-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/softwareEngineering/Development.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-software-engineering-development-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-software-engineering-mvp-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/softwareEngineering/Mvp.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-software-engineering-mvp-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-software-engineering-refactorings-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/softwareEngineering/Refactorings.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-software-engineering-refactorings-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-stack-over-flow-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/StackOverFlow.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-stack-over-flow-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-tech-videos-description-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/techVideos/Description.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-tech-videos-description-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-tech-videos-educational-content-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/techVideos/EducationalContent.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-tech-videos-educational-content-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-welcome-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/Welcome.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-welcome-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-workshops-educational-approach-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/components/workshops/EducationalApproach.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-components-workshops-educational-approach-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-other-imprint-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/other/imprint.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-other-imprint-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-other-privacy-policy-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/other/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-other-privacy-policy-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-pages-workshops-react-hooks-deep-dive-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/pages/workshops/react-hooks-deep-dive.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-pages-workshops-react-hooks-deep-dive-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-pages-workshops-react-native-advanced-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/pages/workshops/react-native-advanced.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-pages-workshops-react-native-advanced-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-pages-workshops-react-state-management-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/pages/workshops/react-state-management.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-pages-workshops-react-state-management-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-posts-blitzjs-index-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/posts/blitzjs/index.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-posts-blitzjs-index-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-posts-entrepreneur-first-index-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/posts/entrepreneur-first/index.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-posts-entrepreneur-first-index-mdx" */),
  "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-posts-remote-learning-index-mdx": () => import("./../../../src/pages/{mdx.fields__slug}.tsx?__contentFilePath=/opt/build/repo/content/posts/remote-learning/index.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-slug-tsx-content-file-path-opt-build-repo-content-posts-remote-learning-index-mdx" */),
  "component---src-pages-public-speaking-tsx": () => import("./../../../src/pages/public-speaking.tsx" /* webpackChunkName: "component---src-pages-public-speaking-tsx" */),
  "component---src-pages-science-communication-tsx": () => import("./../../../src/pages/science-communication.tsx" /* webpackChunkName: "component---src-pages-science-communication-tsx" */),
  "component---src-pages-skills-tsx": () => import("./../../../src/pages/skills.tsx" /* webpackChunkName: "component---src-pages-skills-tsx" */),
  "component---src-pages-software-engineering-tsx": () => import("./../../../src/pages/software-engineering.tsx" /* webpackChunkName: "component---src-pages-software-engineering-tsx" */),
  "component---src-pages-tech-videos-tsx": () => import("./../../../src/pages/tech-videos.tsx" /* webpackChunkName: "component---src-pages-tech-videos-tsx" */),
  "component---src-pages-technology-tsx": () => import("./../../../src/pages/technology.tsx" /* webpackChunkName: "component---src-pages-technology-tsx" */),
  "component---src-pages-workshops-tsx": () => import("./../../../src/pages/workshops.tsx" /* webpackChunkName: "component---src-pages-workshops-tsx" */),
  "component---src-pages-writing-tsx": () => import("./../../../src/pages/writing.tsx" /* webpackChunkName: "component---src-pages-writing-tsx" */)
}

