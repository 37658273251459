import React, { FC } from "react";
// import { Tooltip as ReactTooltip } from "react-tooltip";
import { Flex, Tooltip } from "@chakra-ui/react";

import links from "../data/profiles";

import Link from "./Link";

type ProfileLinksProps = {
  iconSize?: number;
};

const ProfileLinks: FC<ProfileLinksProps> = ({ iconSize }) => (
  <Flex gap={2} wrap={{ base: "wrap", md: "nowrap" }}>
    {links.map((link, key) => (
      // <Tooltip hasArrow label={link.name} key={link.name} placement="bottom">
      <Link
        key={link.name}
        color="background.primary"
        background="none"
        opacity={1}
        transition="all 0.2s"
        _hover={{
          color: "brandSecondary.500",
          transform: "scale(1.1)",
        }}
        data-tip={link.name}
        href={link.url}
        target={"_blank"}
      >
        {link.renderLink(iconSize)}
      </Link>
      // </Tooltip>
    ))}
  </Flex>
);

// <ReactTooltip place="bottom" variant="dark" />

export default ProfileLinks;
