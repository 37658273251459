import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

import de from "../locales/de/translation.json";
import en from "../locales/en/translation.json";

const resources = {
  en: { translation: { ...en } },
  // TODO: Enable German translation
  // de: { translation: { ...de } },
};

const DETECTION_OPTIONS = {
  order: ["querystring", "localStorage", "navigator"],
};

i18n
  .use(HttpApi)
  // https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // see opts @ https://www.i18next.com/overview/configuration-options
  .init({
    detection: DETECTION_OPTIONS,
    fallbackLng: "en",
    /**
     * Show language code only. E.g. show 'en' instead of 'en-US'
     */
    load: "languageOnly",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false, // fixes 'no fallback UI was specified' in react i18next when using hooks
    },
    resources,
  });

export default i18n;
