import React, { FunctionComponent } from "react";
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby";
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  useColorModeValue,
} from "@chakra-ui/react";

import { Optional } from "../common/types/Optional";

const disableFocus = {
  _focus: {
    outline: "none",
  },
};

export type LinkProps = Optional<GatsbyLinkProps<unknown>, "to"> &
  ChakraLinkProps;

const Link: FunctionComponent<LinkProps> = ({
  children,
  to,
  ...otherProps
}) => {
  const color = useColorModeValue("link.primary", "link.primaryDark");
  const colorHover = useColorModeValue("link.hover", "link.hoverDark");

  // Add link text styles if text is inside link.
  const textStyles =
    typeof children === "string"
      ? {
          color: color,
          _hover: {
            color: colorHover,
            textDecoration: "none",
          },
        }
      : {
          _hover: {
            color: "inherit",
            textDecoration: "none",
            transform: "translateY(-1px) scale(1.02)",
          },
        };

  const isInternalPageLink = to !== undefined;

  return (
    <ChakraLink
      as={isInternalPageLink ? GatsbyLink : "a"}
      to={to}
      isExternal={isInternalPageLink ? false : true}
      {...disableFocus}
      {...textStyles}
      {...otherProps}
    >
      {children}
    </ChakraLink>
  );
};

export default Link;
