import { useTranslation } from "react-i18next";

const useHeaderMenuItems = () => {
  const { t } = useTranslation();

  const parts = {
    technology: {
      title: `💡 ${t("categories.technology")}`,
      items: [
        {
          page: "/software-engineering",
          icon: "💻",
          name: t("header.softwareEngineering"),
        },
        {
          page: "/hackathons",
          icon: "⛏",
          name: t("header.hackathons"),
        },
        {
          page: "/tech-videos",
          icon: "🎬",
          name: t("header.techVideos"),
        },
        // {
        //   page: "/open-source",
        //   icon: "☃️",
        //   name: "Open Source",
        // },
      ],
    },
    education: {
      title: `🎓 ${t("categories.education")}`,
      items: [
        {
          page: "/workshops",
          icon: "🍇",
          name: t("header.workshops"),
        },
        {
          page: "/science-communication",
          icon: "🔬",
          name: t("header.scienceCommunication"),
        },
        // {
        //   page: "/online-course",
        //   icon: "🐬️",
        //   name: t("header.onlineCourse"),
        // },
        {
          page: "/chinese",
          icon: "🐉",
          name: t("header.chinese"),
        },
      ],
    },
    entertainment: {
      title: `🎮 ${t("categories.entertainment")}`,
      items: [
        {
          page: "/public-speaking",
          icon: "🎤",
          name: t("header.publicSpeaking"),
        },
        {
          page: "/event-art",
          icon: "🎨",
          name: t("header.eventArt"),
        },
      ],
    },
  };
  return parts;
};

export default useHeaderMenuItems;
