import { Text, TextProps, useColorModeValue } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import { ReactChild, ReactNode } from "react";

interface SubMenuHeaderTextProps extends TextProps {
  onClick?: React.MouseEventHandler;
}
const SubMenuHeaderText: React.FC<
  PropsWithChildren<SubMenuHeaderTextProps>
> = ({ children, onClick, ...props }) => {
  const color = useColorModeValue("rgb(230,121,0)", "rgb(230,121,0)");

  return (
    <Text
      display="inline-block"
      cursor="pointer"
      sx={{
        background: `rgba(0, 0, 0, 0) linear-gradient(transparent 70%, ${color} 0px) repeat scroll 0% 0%`,
      }}
      fontWeight="bold"
      fontSize={{ base: "lg", md: "2xl" }}
      wordBreak="break-all"
      alignSelf="end"
      color="white"
      mb={2}
      onClick={onClick}
      {...props}
    >
      {children}
    </Text>
  );
};

export default SubMenuHeaderText;
