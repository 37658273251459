import React from "react";
import { createIcon } from "@chakra-ui/icon";

const DevPostIcon = createIcon({
  displayName: "DevPostIcon",
  viewBox: "0 0 512 444",
  path: [
    <path
      key="DevPostIconPath"
      d="M128.043 0.353516L0 222.092L128.043 443.66H383.957L512 222.092L383.957 0.353516H128.043ZM162.027 87.4788H246.229C323.136 87.4788 380.117 123.639 380.117 222.092C380.117 316.727 311.637 356.535 242.389 356.535H162.027V87.4788ZM215.723 139.724V304.29H242.197C298.645 304.29 324.587 271.223 324.587 221.922C324.779 167.116 301.205 139.724 244.224 139.724H215.723Z"
      fill="currentColor"
    />,
  ],
});

export default DevPostIcon;
