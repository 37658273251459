import React from "react";
import {
  FaYoutube as Youtube,
  FaGithub as Github,
  FaStackOverflow as StackOverflow,
  FaLinkedin as Linkedin,
  FaTwitter as Twitter,
  FaInstagram as Instagram,
} from "react-icons/fa";
import { IoIosMail as Mail } from "react-icons/io";
import Icon from "@chakra-ui/icon";
import DevPostIcon from "../components/icons/DevPostIcon";

const iconSize = 32;

const profiles = [
  {
    name: "Github",
    renderLink: (size?: number) => <Github size={size || iconSize} />,
    url: "https://github.com/andrekovac",
  },
  {
    name: "Stack Overflow",
    renderLink: (size?: number) => <StackOverflow size={size || iconSize} />,
    url: "http://stackoverflow.com/users/3210677/andru",
  },
  {
    name: "Devpost (Hackathons)",
    renderLink: (size?: number) => (
      <Icon
        as={DevPostIcon}
        height="auto"
        w={size ? `${size}px` : `${iconSize}px`}
        size={size || `${iconSize}px`}
      />
    ),
    url: "https://devpost.com/Andrusch",
  },
  {
    name: "LinkedIn",
    renderLink: (size?: number) => <Linkedin size={size || iconSize} />,
    url: "https://www.linkedin.com/in/andrekovac/",
  },
  {
    name: "Youtube",
    renderLink: (size?: number) => <Youtube size={size || iconSize} />,
    url: "https://www.youtube.com/channel/UCC_JohqFyLklUrl_1fDJARw",
  },
  {
    name: "Instagram",
    renderLink: (size?: number) => <Instagram size={size || iconSize} />,
    url: "https://www.instagram.com/andre.kovac/",
  },
  {
    name: "Twitter",
    renderLink: (size?: number) => <Twitter size={size || iconSize} />,
    url: "https://twitter.com/andrekovac",
  },
  {
    name: "Email",
    renderLink: (size?: number) => <Mail size={size || iconSize} />,
    url: "mailto:info@andrekovac.com",
  },
];

export default profiles;
