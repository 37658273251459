import React from "react";
import {
  Box,
  BoxProps,
  Container,
  Flex,
  HStack,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { navigate } from "gatsby";

import Link from "./Link";
import { LanguageSelector } from "./LanguageSelector";
import DarkModeSwitch from "./DarkModeSwitch";
import ProfileLinks from "./ProfileLinks";
import { FaHouseUser } from "react-icons/fa";

const Ball: React.FC<BoxProps> = props => (
  <Box
    width={3}
    height={3}
    bg="background.primary"
    borderRadius="full"
    {...props}
  />
);

type FooterLinkProps = {
  page: string;
  title: string;
};

const FooterLink: React.FC<FooterLinkProps> = ({ title, page }) => (
  <>
    <Ball display={{ base: "none", md: "block" }} />
    <Link
      to={page}
      color="background.primary"
      _hover={{
        color: "brand.100",
      }}
    >
      {title}
    </Link>
  </>
);

type FooterProps = {
  currentPage: string;
};

const Footer: React.FC<FooterProps> = ({ currentPage }) => {
  return (
    <footer>
      <Flex bg="primary" direction="column">
        <Container flexDirection="column" maxW="container.lg" py={4}>
          <Flex align="center" justify="center" my={10} gap={10}>
            {/* TODO: Enable language selector */}
            {/* <LanguageSelector /> */}
            {currentPage !== "/" && (
              <IconButton
                aria-label="back home"
                bg="transparent"
                fontSize="20px"
                icon={<FaHouseUser />}
                onClick={() => navigate("/")}
                color="background.primary"
              />
            )}
            <DarkModeSwitch />
          </Flex>
          <Flex justify="center" pb={12}>
            <ProfileLinks iconSize={24} />
          </Flex>
          <HStack
            display="flex"
            flex={1}
            spacing={{ base: 2, md: 3 }}
            justify="center"
            mb={4}
          >
            <FooterLink title={"Imprint"} page="/imprint" />
            <FooterLink title={"Privacy Policy"} page="/privacy-policy" />
            <FooterLink title={"Bio"} page="/bio" />
            <FooterLink title={"Skills"} page="/skills" />
          </HStack>
          <Box display="flex" justifyContent="center" py={1}>
            <Text color="background.primary">© André Kovac 2023</Text>
          </Box>
        </Container>
      </Flex>
    </footer>
  );
};

export default Footer;
