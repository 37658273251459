import React, { MouseEventHandler } from "react";
import { Box } from "@chakra-ui/react";

import useHeaderMenuItems from "../../hooks/useHeaderMenuItems";

import { SubMenu, SubMenuIcon, SubMenuItem, SubMenuName } from "./SubMenu";
import SubMenuHeaderText from "./SubMenuHeaderText";

type PartProps = {
  open: boolean;
  part: "technology" | "education" | "entertainment";
  onTitleClick?: MouseEventHandler;
  currentPage: string;
};

const Part: React.FC<PartProps> = ({
  open,
  part,
  onTitleClick,
  currentPage,
}) => {
  const parts = useHeaderMenuItems();

  return (
    <Box>
      {/* <SubMenuHeaderText gridArea="sa1">{parts[part].title}</SubMenuHeaderText> */}
      <SubMenuHeaderText onClick={onTitleClick}>
        {parts[part].title}
      </SubMenuHeaderText>
      <Box>
        <SubMenu open={open}>
          {parts[part].items.map(part => (
            <SubMenuItem
              selected={currentPage.includes(part.page)}
              to={part.page}
              key={part.name}
            >
              <SubMenuIcon>{part.icon}</SubMenuIcon>
              <SubMenuName>{part.name}</SubMenuName>
            </SubMenuItem>
          ))}
        </SubMenu>
      </Box>
    </Box>
  );
};

export default Part;
